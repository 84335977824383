@use "/src/shared/styles/mixins/position.scss";
@use "/src/shared/styles/mixins/media.scss";

$bottom-position: calc(var(--size-bottom-navigation) + 8px);

.ScrollUpButton {
  @include position.fixed-right(16);
  bottom: 32px;
  z-index: var(--z-index-scroll-up-button);

  &.hasNavigation {
    bottom: $bottom-position;
  }

  button {
    box-shadow: 0px 0.5px 2px #00000040;
    border-color: var(--color-gray-light);
  }
}
