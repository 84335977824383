$size-max-width: 680px;

:root {
  --size-max-width: #{$size-max-width};
  --size-max-width-modal: calc(var(--size-max-width) - 16px);

  --size-gnb: 60px;
  --size-lnb: 48px;
  --size-bottom-navigation: 68px;
}
