@use "/src/shared/styles/mixins/flexbox.scss";

.HomeNavigation {
  width: 100%;
  overflow-x: scroll;

  padding: 0 var(--layout-header-padding);

  .list {
    height: var(--size-lnb);
    padding-right: 8px;
  }

  .link {
    @include flexbox.flexbox();
    gap: 4px;

    position: relative;

    padding: 0 8px;

    &:last-of-type {
      padding-right: 16px;
    }

    &::after {
      content: "";
      position: absolute;
      left: 12px;
      right: 4px;
      bottom: 0;

      display: block;
      height: 2px;
    }

    &.isActive {
      color: var(--color-red-light);

      &::after {
        background-color: var(--color-red-light);
        opacity: 0.8;
      }
    }

    .image {
      width: 16px;
      height: 16px;
    }

    .name {
      font-family: var(--font-NotoSans_500);
      white-space: nowrap;
    }
  }
}
