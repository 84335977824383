@mixin mobile {
  // var(--size-max-width)
  @media (max-width: 680px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 681px) {
    @content;
  }
}
