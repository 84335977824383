@use "/src/shared/styles/mixins/media.scss";

@mixin fixed() {
  position: fixed;

  width: 100%;
  max-width: var(--size-max-width);

  margin: 0 auto;
}

@mixin fixed-right($right) {
  position: fixed;
  right: 50%;
  transform: translate(calc(var(--size-max-width) / 2 - #{$right}px));

  @include media.mobile {
    right: $right;
    transform: none;
  }
}

@mixin fixed-center() {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fixed-center-x() {
  position: fixed;
  right: 50%;
  transform: translateX(50%);
}
