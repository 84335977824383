@use "/src/shared/styles/mixins/media.scss";
@use "/src/shared/styles/constants/sizes.scss" as sizes;

// fade-in ================================
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin fade-in($duration: 200ms) {
  animation: fadeIn $duration ease-in-out backwards;
}

// scroll-up ================================
@keyframes scroll-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@mixin scroll-up() {
  animation: scroll-up 250ms ease-in-out backwards;
}

// scroll-left ================================
@keyframes scroll-left {
  from {
    opacity: 0.3;
    transform: translateX(calc(#{sizes.$size-max-width} / 2 + 10px));
  }

  to {
    opacity: 1;
    transform: translateX(calc(#{sizes.$size-max-width} / 2));
  }
}

@keyframes scroll-left-mobile {
  from {
    transform: translateX(30%);
  }

  to {
    transform: translateX(0);
  }
}

@mixin scroll-left() {
  animation: scroll-left 250ms ease-in-out backwards;

  @include media.mobile {
    animation-name: scroll-left-mobile;
  }
}

// spin ================================
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin spin() {
  animation: spin 1s linear infinite;
}
